import React from "react";
import Img from "gatsby-image";
import {graphql, useStaticQuery} from "gatsby";
import { CarouselProvider, Slider, Slide } from 'pure-react-carousel';
import styled from "styled-components";

const ImageWrapper = styled.div`
  .gatsby-image-wrapper {
    height: 805px !important;
    width: 370px !important;
  }
  
  @media (max-width: 991px) {
  
     .gatsby-image-wrapper {
      display: block !important;
    }
  }

`;

export const DeviceSlider = () => {
    const images = useStaticQuery(graphql`
                query {
                    index0: file(relativePath: { eq: "ASIA.PNG" }) {
                        childImageSharp {
                            fluid(maxHeight: 650) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    },
                    index1: file(relativePath: { eq: "ASIA_Menu.PNG" }) {
                        childImageSharp {
                            fluid(maxHeight: 650) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    },
                    index2: file(relativePath: { eq: "BACO.PNG" }) {
                        childImageSharp {
                            fluid(maxHeight: 650) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    },
                    index3: file(relativePath: { eq: "BACO_Menu.PNG" }) {
                        childImageSharp {
                            fluid(maxHeight: 650) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    },
                    index4: file(relativePath: { eq: "DaToni.PNG" }) {
                        childImageSharp {
                            fluid(maxHeight: 650) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    },
                    index5: file(relativePath: { eq: "DaToni_Menu.PNG" }) {
                        childImageSharp {
                            fluid(maxHeight: 650) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    },
                    index6: file(relativePath: { eq: "Milano.PNG" }) {
                        childImageSharp {
                            fluid(maxWidth: 350) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    },
                    index7: file(relativePath: { eq: "Milano_Menu.PNG" }) {
                        childImageSharp {
                            fluid(maxWidth: 350) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                }
        `);

        return <div>
            <div style={{transform: "scale(0.8)"}} className="marvel-device iphone-x">
                <div className="notch">
                    <div className="camera"></div>
                    <div className="speaker"></div>
                </div>
                <div className="top-bar"></div>
                <div className="sleep"></div>
                <div className="bottom-bar"></div>
                <div className="volume"></div>
                <div className="overflow">
                    <div className="shadow shadow--tr"></div>
                    <div className="shadow shadow--tl"></div>
                    <div className="shadow shadow--br"></div>
                    <div className="shadow shadow--bl"></div>
                </div>
                <div className="inner-shadow"></div>
                <div className="screen">
                    <CarouselProvider
                        isPlaying={true}
                        infinite={true}

                        naturalSlideWidth={300}
                        naturalSlideHeight={650}
                        totalSlides={8}
                    >
                        <Slider>
               <Slide index={0}>
                                <ImageWrapper><Img fluid={images.index0.childImageSharp.fluid} /></ImageWrapper>
                            </Slide>
                            <Slide index={1}>
                                <ImageWrapper><Img fluid={images.index1.childImageSharp.fluid} /></ImageWrapper>
                            </Slide>
                            <Slide index={2}>
                                <ImageWrapper><Img fluid={images.index2.childImageSharp.fluid} /></ImageWrapper>
                            </Slide>
                            <Slide index={3}>
                                <ImageWrapper><Img fluid={images.index3.childImageSharp.fluid} /></ImageWrapper>
                            </Slide>
                            <Slide index={4}>
                                <ImageWrapper><Img fluid={images.index4.childImageSharp.fluid} /></ImageWrapper>
                            </Slide>
                            <Slide index={5}>
                                <ImageWrapper><Img fluid={images.index5.childImageSharp.fluid} /></ImageWrapper>
                            </Slide>
                            <Slide index={6}>
                                <ImageWrapper><Img fluid={images.index6.childImageSharp.fluid} /></ImageWrapper>
                            </Slide>
                            <Slide index={7}>
                                <ImageWrapper><Img fluid={images.index7.childImageSharp.fluid} /></ImageWrapper>
                            </Slide>
                        </Slider>
                    </CarouselProvider>
                </div>
            </div>
        </div>;/*<Img fluid={images.iphone.childImageSharp.fluid} />;*/
};
