import styled from "styled-components"

export const Headline = styled.h2`
  color: #760f19;
  font-weight: 500;
  font-size: 40px;
  text-transform: uppercase;
  @media (max-width: 991px) {
    text-align: center;
   
  }
`;
