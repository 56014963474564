import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import styled, { css } from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"
import { Headline } from "../components/headline"
import { Button } from "../components/button"
import { AppDetails } from "../components/appDetails"
import QRCodeSVG from "../images/qr-code.svg";

const Separator = styled.hr`
  width: 50px;
  height: 2px;
  background-color: #222;
  @media (max-width: 991px) {
    margin: 0 auto 40px;
  }
`

const QrCode = styled.div`
margin-bottom: 20px;
@media (max-width: 767px){
  svg {
    max-width: 400px;
    width: 100%;
  }
}
`

const Paragraph = styled.p`
  font-size: 20px;
  margin: 0 0 10px;
`

const Bolded = styled.strong`
  font-family: Helvetica Neue, sans-serif;
  font-weight: 700;
  font-size: 20px;
`

export const Row = styled.div`
  display: flex;
  max-width: 1140px;
  margin: 0 auto;
  font-family: Helvetica Neue, sans-serif;
  align-items: center;
  min-height: 680px;
`
export const Col = styled.div`
  width: 100%;
  &.max-width-column {
    max-width: 490px;
  }

  @media (max-width: 991px) {
    max-width: 100%;
  }
`

const Devices = styled.div`
  position: relative;
  min-height: 680px;

  @media (max-width: 991px) {
    min-height: 430px;
  }
  .device-1 {
    width: 221px;
    height: 450px;
    z-index: 2;
    transform: translateY(95px);
    position: absolute;
    @media (max-width: 991px) {
      left: 50%;
      transform: translateY(45px) translateX(-25%);
      height: 410px;
      width: 191px;
    }

    @media (min-width: 992px) and (max-width: 1380px) {
      width: 221px;
      transform: translateY(95px) translateX(90px);
    }
  }
  .device-2 {
    position: absolute;
    width: 185px;
    transform: translateY(135px) translateX(150px);
    z-index: 1;
    height: 375px;
    @media (max-width: 991px) {
      left: 50%;
      height: 325px;
      width: 165px;
      transform: translateY(75px) translateX(-140px);
    }

    @media (min-width: 992px) and (max-width: 1380px) {
      height: 325px;
      transform: translateY(135px) translateX(230px);
    }
  }

  .device-3 {
    width: 375px;
    height: 288px;
    z-index: 2;
    right: 0;
    transform: translateY(315px) translateX(-200px);
    position: absolute;

    @media (min-width: 992px) and (max-width: 1380px) {
      right: 0;
      width: 225px;
    }

    @media (max-width: 991px) {
      width: 225px;
      height: 188px;
      right: 50%;
      transform: translateX(60px) translateY(205px);
    }
  }

  .device-4 {
    width: 450px;
    height: 345px;
    z-index: 1;
    right: 0;
    transform: translateY(55px) translateX(100px);
    position: absolute;

    @media (min-width: 991px) and (max-width: 1380px) {
      right: 150px;
      width: 300px;
      transform: translateY(135px) translateX(100px);
    }

    @media (max-width: 991px) {
      width: 250px;
      height: 245px;
      right: 50%;
      transform: translateX(155px) translateY(50px);
    }
  }
`

export const Section = styled.div`
  background-color: ${props => props.color};
  width: 100%;
  ${props =>
    props.centered &&
    css`
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
    `}

  ${props =>
    props.padded &&
    css`
      padding: 120px 0;
    `}
  
   @media (min-width: 992px) and (max-width: 1380px) {
    ${Row} {
      padding: 20px;
    }
  }

  @media (max-width: 991px) {
    ${Row} {
      flex-direction: column;
      padding: 30px;
    }
    
    ${Row}{
      &.no-padding {
        padding: 0 !important;
      }
    }

    .reverse-row {
      flex-direction: column-reverse;
    }
  }
  
  @media (max-width: 767px){
   ${props =>
    props.padded &&
    css`
      padding: 40px 0;
    `}
  }
`

const IndexPage = () => {
  const iphones = useStaticQuery(graphql`
    query {
      iphone1: file(relativePath: { eq: "DaToni_iphonexspacegrey_portrait.png" }) {
        childImageSharp {
          fluid(maxWidth: 225) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      iphone2: file(relativePath: { eq: "ASIA_iphonexspacegrey_portrait.png" }) {
        childImageSharp {
          fluid(maxWidth: 185) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      portraitIpad: file(relativePath: { eq: "portrait-ipad.png" }) {
        childImageSharp {
          fluid(maxWidth: 450) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      landscapeIpad: file(relativePath: { eq: "landscape-ipad.png" }) {
        childImageSharp {
          fluid(maxWidth: 375) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      qrCode: file(relativePath: { eq: "qr-code.png" }) {
        childImageSharp {
          fluid(maxWidth: 120) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Startseite" description={"MainOrder bietet Ihnen eine individuelle Restaurant-App für Ihren Betrieb, nach Ihren Wünschen um ihr Business auf ein neues Niveau zu heben. Egal ob Lieferung, Abholung oder an den Tisch bestellen. Rundum sorglos, ganz ohne versteckte Zusatzkosten."} lang={"de"}  />
      <Section color={"#E8E8E9"}>
        <Row className={"reverse-row"}>
          <Col>
            <Devices>
              <div className="device device-1">
                <Img fluid={iphones.iphone1.childImageSharp.fluid} />
              </div>
              <div className="device device-2">
                <Img fluid={iphones.iphone2.childImageSharp.fluid} />
              </div>
            </Devices>
          </Col>
          <Col>
            <Headline>WIE FUNKTIONIERT'S? </Headline>
            <Separator />
            <Paragraph>
              <Bolded>
                Einfach gesagt: Sie bekommen eine individuelle App für Ihren
                Gastronomiebetrieb.
              </Bolded>
            </Paragraph>
            <Paragraph>
              MainOrder passt sich genau Ihren Wünschen und den Bedürfnissen
              Ihrer Kunden an;
            </Paragraph>
            <Paragraph>
              <Bolded>Ihre Kunden bestellen.</Bolded> Egal ob zum{" "}
              <Bolded>Liefern, Abholen</Bolded> oder an den{" "}
              <Bolded>Tisch</Bolded>.
              <Bolded> Sie empfangen die Bestellung</Bolded> wo Sie möchten. Egal
              ob <Bolded>Tablet, Smartphone, Drucker</Bolded> oder direkt im{" "}
              <Bolded>Kassensystem</Bolded>.
            </Paragraph>
            <Paragraph>
              Ihre Kunden sind schon jetzt digital unterwegs - Wir begleiten
              Ihren Weg zum <Bolded>modernen Restaurant</Bolded> und
              gewährleisten, dass Ihre Kunden{" "}
              <Bolded>einfach und bequem</Bolded> bestellen können.
            </Paragraph>
            <Paragraph>
              <Bolded>
                Heben Sie ihr Business mit MainOrder auf ein neues Niveau!
              </Bolded>
            </Paragraph>
          </Col>
        </Row>
      </Section>

      <Section color={"#F1F1F1"}>
        <Row>
          <Col className={"max-width-col"}>
            <Headline>WIESO EINE APP?</Headline>
            <Separator />
            <Paragraph>
              <Bolded>DER KONKURRENZ VORAUS</Bolded>
            </Paragraph>
            <Paragraph>
              Ihre Speisekarte ist Ihre Visitenkarte. Egal ob Pizza, Steak,
              Sushi oder Vegan. Geben Sie Ihren Gerichten eine Bühne für ein
              großes Publikum.
            </Paragraph>
            <br />

            <Paragraph>
              <Bolded>RUNDUM EFFIZIENT</Bolded>
            </Paragraph>
            <Paragraph>
              Konzentrieren Sie sich voll und ganz auf die Qualität Ihrer
              Speisen. Ihre MainOrder-App übernimmt den Rest für Sie -
              Bestellungen aufnehmen, bezahlen, im Kassensystem verbuchen, kein
              Problem.
            </Paragraph>
            <br />

            <Paragraph>
              <Bolded>EINMALIG EINRICHTEN - DAUERHAFT ZUFRIEDEN</Bolded>
            </Paragraph>
            <Paragraph>
              Direkt mit dem Starter Paket beginnen und Ihre Kunden langfristig
              an Ihr Restaurant binden – die technische Einrichtung übernehmen
              wir.
            </Paragraph>
          </Col>
          <Col>
            <Devices>
              <div className="device device-3">
                <Img fluid={iphones.landscapeIpad.childImageSharp.fluid} />
              </div>
              <div className="device device-4">
                <Img fluid={iphones.portraitIpad.childImageSharp.fluid} />
              </div>
            </Devices>
          </Col>
        </Row>
      </Section>
      <Section centered padded color={"#F8F8F8"}>
        <Headline>VORSCHAU IHRER APP</Headline>
        <Separator />
        <QrCode>
          <QRCodeSVG />
        </QrCode>
        <Link to={"/preview"}><Button>App ansehen</Button></Link>
      </Section>
      <Section centered padded color={"#fff"}>
        <a aria-label={"Sektion: Auf einen Blick"} href={"#overview"} name={"overview"}></a>
        <Headline>AUF EINEN BLICK</Headline>

        <Separator />
        <AppDetails />
        <Link to={"/contact"}>
          <Button>Angebot anfragen</Button>
        </Link>
      </Section>
    </Layout>

  )
}

export default IndexPage
