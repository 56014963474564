import React from "react"
import styled, { css } from "styled-components"
import { Col, Row } from "../pages"
import PenToolIcon from "../images/pen-tool-icon.svg"
import CompassIcon from "../images/compass-icon.svg"
import FastForwardIcon from "../images/fast-forward-icon.svg"
import RotateIcon from "../images/rotate-icon.svg"
import ZapIcon from "../images/zap-icon.svg"
import FileTextIcon from "../images/file-text-icon.svg"
import {DeviceSlider} from "./deviceSlider";


const KeyfactTitle = styled.div`
  text-align: right;
  font-size: 30px;
  margin-bottom: 15px;
`

const KeyfactDescription = styled.div`
  font-size: 18px;
  text-align: right;
`

const KeyfactIcon = styled.div`
  padding: 0 10px;
  margin-left: 20px;
  svg {
    width: 45px;
  }
`

const DeviceWrapper = styled.div`
  text-align: center;
  .gatsby-image-wrapper {
    width: 300px;
    margin: 0 auto;
  }
  
  &.mobile-only {
    display: none;
  }
  @media (max-width: 1400px){
    .marvel-device {
    margin-top: -120px;
    }  
  }
   @media (max-width: 767px){
    .marvel-device {
         margin-top: -140px !important;
         margin-bottom: -30px !important;
    }
  }
  
  
`


const KeyfactWrapper = styled.div`
  display: flex;

  min-height: 180px;
  ${props =>
    props.position === "right" &&
    css`
      flex-direction: row-reverse;
    `}

  ${KeyfactTitle} {
    ${props =>
      props.position === "right" &&
      css`
        text-align: left;
      `}

    @media (max-width: 991px) {
      text-align: left !important;
      font-size: 24px !important;
    }
  }

  ${KeyfactDescription} {
    ${props =>
      props.position === "right" &&
      css`
        text-align: left;
      `}
  }

  ${KeyfactIcon} {
    ${props =>
      props.position === "right" &&
      css`
        margin-left: 0;
        margin-right: 20px;
      `}
  }

  @media (max-width: 991px) {
    justify-content: flex-end;
    ${props =>
      props.position !== "right" &&
      css`
        flex-direction: row-reverse;
        justify-content: flex-end;
        ${KeyfactDescription} {
          text-align: left !important;
        }
        ${KeyfactIcon} {
          margin-right: 20px !important;
          margin-left: 0;
        }
      `}
    
    ${KeyfactDescription} {
      padding-right: 20px !important;
    }
  }
  
  @media (max-width: 400px){
    ${KeyfactIcon} {
      margin-left: 40px !important;
      padding: 0 !important;
    }
  }
  

`

const AppDetailWrapper = styled.div`
    @media (min-width: 992px) and (max-width: 1380px) {
        .marvel-device {
           transform: scale(0.7) !important;
        }
        
        ${KeyfactTitle} {
          font-size: 24px !important;
        }
        
        ${KeyfactIcon}{
            svg {
                width: 40px !important;
            }
        }
        
        ${KeyfactWrapper}{
        margin-right: 0 !important;
        position: relative;
        left: 40px;
        margin-bottom: 40px;
        }
        
        .right {
        ${KeyfactWrapper}{
          left: -40px !important;
        }
        }
        
      ${KeyfactDescription}{
          font-size: 15px !important;
      }
    }
        
     @media (max-width: 600px){
       .marvel-device {
       transform: scale(0.8) !important;
       margin-top: -80px !important;
       }
     .row {
     padding: 0 !important;
     } 
    }
    @media (max-width: 400px){ 
      .row {
        padding: 0 !important;
      }
      
      .marvel-device {
        transform: scale(0.6) !important;
        margin-top: -180px !important;
        margin-bottom: -120px !important;
        overflow: hidden;
      }
      
      .icon {
        margin: 0 10px !important;
        svg {
        width: 24px !important;
        height: 24px !important;
        }
      }
      .mcol {
        width: auto !important;
        max-width: initial !important;
      }
      .col {
        width: 100vw;
      }
      .col.right {
      margin-top: -20px;
      }
    }

`;

const Keyfact = ({ position = "left", icon, title, description }) => {
  return (
    <KeyfactWrapper position={position}>
      <div>
        <KeyfactTitle>{title}</KeyfactTitle>
        <KeyfactDescription>{description}</KeyfactDescription>
      </div>
      <KeyfactIcon className={"icon"}>{icon}</KeyfactIcon>
    </KeyfactWrapper>
  )
}

export const AppDetails = () => {
  return (<AppDetailWrapper>
    <Row className={"row"}>
      <Col className={"col"}>
        <Keyfact
          title={"EINZIGARTIG"}
          description={
            "Individuelles Design - so individuell wie Ihre Speisen, wie Ihr Restaurant, wie Sie."
          }
          icon={<PenToolIcon />}
        />
        <Keyfact
          title={"FLEXIBEL"}
          description={
            "Kein MainOrder-kompatibles Kassensystem? Kein Problem - unsere App gibt es auch ohne Zahlsystem."
          }
          icon={<CompassIcon />}
        />
        <Keyfact
          title={"SIMPEL"}
          description={
            "Vom ersten Kontakt zur fertigen App &. Einrichtung in kürzester Zeit."
          }
          icon={<FastForwardIcon />}
        />
      </Col>
      <Col className={"mcol"}>
        <DeviceWrapper>
          <DeviceSlider />
        </DeviceWrapper>
      </Col>
      <Col className={"col right"}>
        <Keyfact
          position="right"
          title={"FORTSCHRITTLICH"}
          description={
            "Mit ihrer eigenen App inkl. Tisch-Bestellung bieten wir die digitale Lösung der Gastronomie-Zukunft. "
          }
          icon={<ZapIcon />}
        />
        <Keyfact
          position="right"
          title={"ÜBERSICHTLICH"}
          description={
            "Volle Kontrolle & leichte Bedienbarkeit. Behalten Sie alle Umsätze, Bestellungen und Transaktionen im Blick."
          }
          icon={<FileTextIcon />}
        />
        <Keyfact
          position="right"
          title={"ALLROUND"}
          description={
            "Einmalige App-Einrichtung mit Starterpaket inkl. Hardware, Tisch-Aufsteller & Promotion-Flyer."
          }
          icon={<RotateIcon />}
        />
      </Col>
    </Row>
      </AppDetailWrapper>
  )
}
